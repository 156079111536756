import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/config/axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
//import axios from "@/config/axios";
//import config from '../config';
import $ from "jquery";

// Configuración de jQuery como una variable global
window.$ = $;
window.jQuery = $;

import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "@fortawesome/fontawesome-free/js/all";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import config from '../config';
import "bootstrap"

//Token Reactivo
const token = reactive({
  value: localStorage.getItem("token"),
});

// Configurar el interceptor para actualizar el token automáticamente
axios.interceptors.request.use((config) => {
  if (token.value) {
    config.headers.Authorization = `Bearer ${token.value}`;
  }
  return config;
});


const app = createApp(App)
  .use(store)
  .use(router)
  .use(config)
  .use(Vue3Toasity)
  .use(VueSweetalert2);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$token = token;
app.config.globalProperties.$Swal  = VueSweetalert2;

app.mount("#app");
