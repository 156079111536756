<template>
  <div>
    <Transition name="slide-fade">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/Footer';
import LoginDataService from "./services/LoginDataService";
//import PruebaWebPhone from '@/components/PruebaWebPhone';
//import  '@/assets/js/custom.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
//import config from '../config';

export default {
  computed: {
    ...mapState(['generalRunning']),
    ...mapGetters(['formattedGeneralTime', 'formattedSpeakingTime']),
  },
  components: {
    Footer
  },
  setup() {


  }, data() {
    return {
      urlWs: "",
      app: true,
      id_usuario: localStorage.getItem("id_usuario"),
      id_campana: localStorage.getItem("id_campana"),
      rol: localStorage.getItem("rol")
    }
  }, async mounted() {
    

    /***
     * const dev = config.APP_DEV;
     * 
     *   if (dev) {
      this.urlWs = config.WEBSOCKET_DEV;
    } else {
      this.urlWs = config.WEBSOCKET_PROD;
    }
    const socket = new WebSocket(this.urlWs);
    await socket.addEventListener('open', () => {
      console.log('Connected to the server');
    });

    this.$socket = socket;
     * 
     * 
    */

  

    await window.addEventListener('beforeunload', this.handleWindowClose);

  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowClose);
  },
  methods: {
    async handleWindowClose(event) {
      event;

      let data = {
        general : this.formattedGeneralTime,
        id_campana : this.id_campana,
        id_usuario: this.id_usuario
      }

      await LoginDataService.logOut(data).then(response => {
        const logout = {
          event: logout
        }
        this.$socket.send(logout);
        this.$router.push("/login");
        response;

      });
    }
  } ,     ...mapMutations(['startStopGeneral', 'resetGeneral']),
        ...mapActions(['setGeneralTime','setSpeakingTime','setTypingTime']),
}
</script>

<style>
@import "./assets/css/style.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
