import http from "../config/axios";
import config from "../../config";

class LoginDataService {
  login(data) {
    return http.post(`/login`, data);
  }

  token() {
    return http.post(`/login/token`);
  }

  async logOut(avance) {

    const usuario = localStorage.getItem("usuario");

    const dev = config.APP_DEV;
    var urlWs = "";
    if (dev) {
      urlWs = config.WEBSOCKET_DEV;
    } else {
      urlWs = config.WEBSOCKET_PROD;
    }

    return new Promise((resolve, reject) => {
      const socket = new WebSocket(urlWs);

      socket.addEventListener("open", () => {
        //console.log("Connected to the server");

        const data = {
          usuario: usuario,
          general: avance.general,
          tipificando: avance.tipificando,
          hablando: avance.hablando,
          id_usuario: avance.id_usuario,
          id_campana: avance.id_campana
        };

        const send = {
          event: "logout",
          data: data,
        };

        socket.send(JSON.stringify(send));

        socket.addEventListener("close", () => {
          console.log("WebSocket connection closed.");
          localStorage.clear();
          resolve(http.post(`/logout`, data)); // Resuelve la promesa después de enviar los datos y cerrar el socket
        });

        // Cerrar la conexión WebSocket después de enviar los datos
        socket.close();
      });

      socket.addEventListener("error", (error) => {
        console.error("WebSocket error:", error);
        reject(error); // Rechaza la promesa si hay un error en el WebSocket
      });
      
    });
    
  }
}

export default new LoginDataService();
