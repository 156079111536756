import { createRouter, createWebHistory } from 'vue-router'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css';
import LoginDataService from "../services/LoginDataService";
//import store from '@/store';

var autenticado = false;
async function consultaToken(){
  await LoginDataService.token()
  .then(response => {
    response;
    autenticado = true;
  }).catch(error => {
   autenticado;
    // Capturar el error de Axios
    if (error.response) {      
      // Verificar si la respuesta tiene un estado de 401 (No autorizado)
      console.response;
      if (error.response.status === 401) {
        // Redirigir a la página de inicio de sesión
        window.location.href = '/login';
        autenticado = false;
      }
    } else {
      // Manejar otros errores de Axios o de red
      console.error('Error en la solicitud:', error.message);
    }
  });
}


const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue'),
    beforeEnter:(to, from, next) => {
      var token = localStorage.getItem('token');
      consultaToken();

      if(token != null){
        consultaToken();
        next();
      }else{
        next({name:'login'})
      }
     
    },
    children:[
      {
        path: '/',
        component: () => import(/* homeview*/ '../views/ResumenView')
      },
      {
        path: '/campanas',
        component: () => import(/* homeview*/ '../views/CampañasView')
      },
      {
        path: '/usuarios',
        component: () => import(/* homeview*/ '../views/UsuariosView'),
        children:[
          {
          path: '/usuarios',
          component: () => import(/* homeview*/ '../views/AgentesView'),
        },
        {
          path: '/agentes',
          component: () => import(/* homeview*/ '../views/AgentesView'),
        },
        {
          path: '/supervisores',
          component: () => import(/* homeview*/ '../views/SupervisoresView'),
        },
        {
          path: '/monitores',
          component: () => import(/* homeview*/ '../views/MonitoresView'),
        },
        {
          path: '/skills',
          component: () => import(/* homeview*/ '../views/SkillsView'),
        }
        ]
      }
      ,
      {
        path: '/monitor',
        component: () => import(/* homeview*/ '../views/MonitorView')
      }
      ,
      {
        path: '/reporteria',
        component: () => import(/* homeview*/ '../views/ReporteriaView'),
        children:[
          {
            path: '/tmo',
            component: () => import(/* homeview*/ '../views/TmoView.vue'),
          },
          {
            path: '/llamadas',
            component: () => import(/* homeview*/ '../views/LlamadasView.vue'),
          }
        ]
      }
      ,
      {
        path: '/Bloqueos',
        component: () => import(/* homeview*/ '../views/BloqueosView.vue')
      }      ,
      {
        path: '/Parametros',
        component: () => import(/* homeview*/ '../views/ParametrosCampana.vue')
      },
      {
        path: '/Configuraciones',
        component: () => import(/* homeview*/ '../views/ConfiguracionesView.vue'),
        children:[
          {
          path: '/formularios',
          component: () => import(/* homeview*/ '../views/FormulariosView'),
        },
        {
          path: '/apikeys',
          component: () => import(/* homeview*/ '../views/ApiKeysView'),
        },
        {
          path: '/pausas',
          component: () => import(/* homeview*/ '../views/PausasView'),
        }
      ]
      }
      ,
        {
          path: '/grabaciones',
          component: () => import(/* homeview*/ '../views/GrabacionesView'),
        }
        ,
        {
          path: '/gestion',
          component: () => import(/* homeview*/ '../views/PanelGestion'),
        }
      
    ]
  },
  { path: '/:pathMatch(.*)*', beforeEnter: (to, from, next) => { next('/404') } },
  {
      path: '/404',
      name: '404',
      component: () => import('../views/404')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next)=>{
  to;
  from;
  Nprogress.start();
  next();
  Nprogress.done();
})

export default router
