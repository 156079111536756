import { createStore } from 'vuex';

function tiempoEnSegundos(tiempoString) {
  // Dividir la cadena en horas, minutos y segundos
  const [horasStr, minutosStr, segundosStr] = tiempoString.split(':');

  // Convertir las partes a números enteros
  const horas = parseInt(horasStr, 10);
  const minutos = parseInt(minutosStr, 10);
  const segundos = parseInt(segundosStr, 10);

  // Calcular el tiempo total en segundos
  const tiempoTotal = horas * 3600 + minutos * 60 + segundos;

  return tiempoTotal;
}

export default createStore({
  state() {
    return {
      token: localStorage.getItem('token') || '',
      generalRunning: false,
      generalTime: 0,
      generalInterval: null,
      speakingRunning: false,
      speakingTime: 0,
      speakingInterval: null,
      typingRunning: false,
      typingTime: 0,
      typingInterval: null,
      atendidos: 0,
      abandonados: 0,
    };
  },
  getters: {
    getToken: state => state.token,
    formattedGeneralTime(state) {
      const hours = Math.floor(state.generalTime / 3600);
      const minutes = Math.floor((state.generalTime % 3600) / 60);
      const seconds = state.generalTime % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    formattedSpeakingTime(state) {

      const hours = Math.floor(state.speakingTime / 3600);
      const minutes = Math.floor((state.speakingTime % 3600) / 60);
      const seconds = state.speakingTime % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;


    },
    formattedTypingTime(state) {

      
      const hours = Math.floor(state.typingTime / 3600);
      const minutes = Math.floor((state.typingTime % 3600) / 60);
      const seconds = state.typingTime % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;


    },
    getAtendidos: state => state.atendidos,
    getAbandonados: state => state.abandonados,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    startStopGeneral(state) {
      if (state.generalRunning) {
        clearInterval(state.generalInterval);
      } else {
        state.generalInterval = setInterval(() => {
          state.generalTime++;
        }, 1000);
      }
      state.generalRunning = !state.generalRunning;
    },
    resetGeneral(state) {
      clearInterval(state.generalInterval);
      state.generalRunning = false;
      state.generalTime = 0;
    },
    startStopSpeaking(state) {
      if (state.speakingRunning) {
        clearInterval(state.speakingInterval);
      } else {
        state.speakingInterval = setInterval(() => {
          state.speakingTime++;
        }, 1000);
      }
      state.speakingRunning = !state.speakingRunning;
    },
    resetSpeaking(state) {
      clearInterval(state.speakingInterval);
      state.speakingRunning = false;
      state.speakingTime = 0;
    },
    startStopTyping(state) {
      if (state.typingRunning) {
        clearInterval(state.typingInterval);
      } else {
        state.typingInterval = setInterval(() => {
          state.typingTime++;
        }, 1000);
      }
      state.typingRunning = !state.typingRunning;
    },
    resetTyping(state) {
      clearInterval(state.typingInterval);
      state.typingRunning = false;
      state.typingTime = 0;
    },
    setGeneralTime(state, tiempoString) {
      const tiempoEnSeg = tiempoEnSegundos(tiempoString);
      state.generalTime = tiempoEnSeg;
    },
    setSpeakingTime(state, tiempoString) {
      const tiempoEnSeg = tiempoEnSegundos(tiempoString);
      state.speakingTime = tiempoEnSeg;
    },
    setTypingTime(state, tiempoString) {
      const tiempoEnSeg = tiempoEnSegundos(tiempoString);
      state.typingTime = tiempoEnSeg;
    },
    incrementAtendidos(state) {
      state.atendidos++;
    },
    incrementAbandonados(state) {
      state.abandonados++;
    },
    resetAtendidos(state) {
      state.atendidos = 0;
    },
    resetAbandonados(state) {
      state.abandonados = 0;
    },
  },
  actions: {
    updateToken({ commit }, token) {
      commit('setToken', token);
    },
    setGeneralTime({ commit }, time) {
      commit('setGeneralTime', time);
    },
    setSpeakingTime({ commit }, time) {
      commit('setSpeakingTime', time);
    },
    setTypingTime({ commit }, time) {
      commit('setTypingTime', time);
    },
    incrementAtendidos({ commit }) {
      commit('incrementAtendidos');
    },
    incrementAbandonados({ commit }) {
      commit('incrementAbandonados');
    },
    resetAtendidos({ commit }) {
      commit('resetAtendidos');
    },
    resetAbandonados({ commit }) {
      commit('resetAbandonados');
    },
  },
  modules: {}
});